import { createAsyncThunk } from "@reduxjs/toolkit";
// urls
import * as URLS from "../../urls";
//axios
import axios from "../../utils/axios";
//_________________________________________________

// GET =>  GET Quick Registration
export const getQuickRegistration = createAsyncThunk(
  "reports/getQuickRegistration",
  async (thunkAPI) => {
    try {
      const response = await axios.get(URLS.getQuickRegistration);

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
// PUT =>  PUT  date court session
export const updateCourtSessionDate = createAsyncThunk(
  "reports/updateCourtSessionDate",
  async (data, thunkAPI) => {
    try {
      const response = await axios.put(
        `${URLS.court_sessions}/${data?.id}/update_upcoming_session_date/`,
        { upcoming_session_date: data?.upcoming_session_date }
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// PUT =>  PUT   procedure statement
export const updateProcedureStatement = createAsyncThunk(
  "reports/updateProcedureStatement",
  async ({ id, procedure_statement }, thunkAPI) => {
    try {
      const response = await axios.put(
        `${URLS.court_sessions}/${id}${URLS.update_procedure_statement}`,
        { procedure_statement }
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// PUT =>  PUT   update assigned employee
export const updateAssignedEmployee = createAsyncThunk(
  "reports/updateAssignedEmployee",
  async ({ id, assigned_employee_id }, thunkAPI) => {
    try {
      const response = await axios.put(
        `${URLS.court_sessions}/${id}${URLS.update_assigned_employee}/`,
        { assigned_employee_id }
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET =>  GET Teplate List
export const getTemplatesListService = createAsyncThunk(
  "reports/getTemplatesListService",
  async (thunkAPI) => {
    try {
      const response = await axios.get(URLS.getTemplatesListURL);

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET =>  GET Teplate Field
export const getTemplateFieldsService = createAsyncThunk(
  "reports/getTemplateFieldsService",
  async (thunkAPI) => {
    try {
      const response = await axios.get(URLS.gettemplateFieldsURL);

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET =>  GET Teplate Service
export const createTemplateService = createAsyncThunk(
  "reports/createTemplateService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.createTemplateURL, data);

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET =>  GET REPORT Service
export const createReportService = createAsyncThunk(
  "reports/createReportService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.createReportURL, data);

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
// GET =>  GET REPORT Service
export const createSessionReportService = createAsyncThunk(
  "reports/createSessionReportService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(
        URLS.create_report_court_sessions,
        data
      );

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET =>  GET Template Client List
export const getTemplatesClientListService = createAsyncThunk(
  "reports/getTemplatesClientListService",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(URLS.getTemplatesClientListURL);

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
// GET =>  GET Template Court sessions
export const getTemplatesCourtSessionsListService = createAsyncThunk(
  "reports/getTemplatesCourtSessionsListService",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(URLS.getTemplatesCourtSessionsListURL);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET =>  GET TEMPLATE CLIENT Field
export const getTemplateClientFieldsService = createAsyncThunk(
  "reports/getTemplateClientFieldsService",
  async (thunkAPI) => {
    try {
      const response = await axios.get(URLS.gettemplateClientFieldsURL);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
// GET =>  GET TEMPLATE Court Sessions Field
export const getTemplateCourtSessionsFieldService = createAsyncThunk(
  "reports/getTemplateCourtSessionsFieldService",
  async (thunkAPI) => {
    try {
      const response = await axios.get(URLS.gettemplateCourtSessionsFieldsURL);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// CREATE =>  CREATE TEMPLATE CLIENT Service
export const createClientTemplateService = createAsyncThunk(
  "reports/createClientTemplateService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.createClientTemplateURL, data);

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// CREATE =>  CREATE TEMPLATE   COURT SESSION Service
export const createCourtSessionTemplateService = createAsyncThunk(
  "reports/createCourtSessionTemplateService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(
        URLS.createCourtSessionTemplateURL,
        data
      );

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
// CREATE =>  CREATE  COURT REPORT
export const createCourtReport = createAsyncThunk(
  "reports/createCourtReport",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(
        URLS.createReportCourtSessionsURL,
        data
      );

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST => CREATE CLIENT REPORT SERVICE
export const createClientReportService = createAsyncThunk(
  "reports/createClientReportService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.createReportClientURL, data);

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET =>  GET Quick Registration
export const getMigrationService = createAsyncThunk(
  "reports/getMigrationService",
  async (thunkAPI) => {
    try {
      const response = await axios.get(URLS.getMigrationURL);

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
