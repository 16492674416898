// routes
import { PATH_DASHBOARD } from "../../../routes/paths";

import SvgColor from "../../../components/svg-color";
// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const ICONS = {
  dash: icon("dash"),
  calendar: icon("calendar-check"),
  check: icon("check-square-broken"),
  file: icon("file-attach-01"),
  coins: icon("coins-rotate"),
  folder: icon("folder-open-03"),
  user: icon("user-profile-01"),
  employee: icon("user-profile-01"),
  phone: icon("phone"),
  settings: icon("settings"),
  note: icon("note"),
  vacationIcon: icon("users-profiles-01"),
};

const navConfig = [
  // Admin
  // ----------------------------------------------------------------------
  {
    subheader: "",
    items: [
      {
        title: "لوحة التحكم",
        icon: ICONS.dash,
        path: "/dashboard/overview",
        index: "1",
      },

      //{
      //  title: " بحث عن خصم",
      // icon: ICONS.dash,
      //  path: "/dashboard/search-oppontnet",
      //  index: "2",
      // }
      {
        title: "الإحصائيات",
        icon: ICONS.dash,
        path: "/dashboard/statistics",
        index: "3",
      },

      {
        title: "المهام والإجراءات",
        icon: ICONS.check,
        path: "/dashboard/tasks-procedures",
        index: "4",
      },

      {
        title: "الجلسات",
        icon: ICONS.check,
        path: "/dashboard/sessions",
        index: "5",
      },
      {
        title: "القضايا",
        icon: ICONS.file,
        path: "/dashboard/lawsuit",
        index: "6",
      },
      //collection
      /* {
        title: "التحصيل",
        icon: ICONS.file,
        path: "/dashboard/collection",
        index: "7",
      },

      //collection -finance
    /*  {
        title: "ماليات التحصيل",
        icon: ICONS.file,
        path: "/dashboard/collection-finance",
        index: "8",
        children: [
          {
            title: "قائمة الكشوفات",
            path: "/dashboard/collection-finance",
          },
          {
            title: "عهدة المصروفات",
            path: "/dashboard/collection-finance/custody-expenses",
          },
        ],
      },*/

      {
        title: "مالي",
        icon: ICONS.coins,
        path: "/dashboard/projects",
        index: "9",
        children: [
          { title: "الفواتير", path: "/dashboard/finance/invoices" },
          {
            title: "عهدة المصروفات",
            path: "/dashboard/finance/custody-expenses",
          },

          // this is new
          {
            title: "الكشوفات",
            path: "/dashboard/finance/judicial-statements",
          },

          { title: "كشف حساب", path: "/dashboard/finance/statements" },
          {
            title: "ايصالات الدفع",
            path: "/dashboard/finance/receipt-voucher",
          },

          {
            title: "الحسابات",
            path: "/dashboard/finance/accounts",
          },

          {
            title: "النفقات العامة",
            path: "/dashboard/finance/general-expenses",
          },

          {
            title: "تاب الأصول",
            path: "/dashboard/finance/assets",
          },

          {
            title: "العقود",
            path: "/dashboard/finance/contracts",
          },
        ],
      },

      {
        title: "التقارير",
        icon: ICONS.note,
        path: "/dashboard/reports",
        index: "10",
      },

      {
        title: "العملاء",
        icon: ICONS.user,
        path: "/dashboard/clients",
        index: "11",
        children: [
          { title: "الموكلين", path: "/dashboard/clients" },
          /*  { title: "potential_clients", path: "/dashboard/clients/potential" },*/
        ],
      },
      // hr
      {
        title: "الموارد البشرية",
        icon: ICONS.vacationIcon,
        path: "/dashboard/hr",
        index: "12",
        children: [
          { title: "الموظفين", path: "/dashboard/users" },

          { title: "الإجازات", path: "/dashboard/hr/vacations" },
          { title: "التقويم العام", path: "/dashboard/hr/general-calender" },

          /*  { title: "potential_clients", path: "/dashboard/clients/potential" },*/
        ],
      },

      //{
      //  title: "الإجازات",
      //  icon: ICONS.vacationIcon,
      //  path: "/dashboard/vacations",
      //   index: "7",
      // },

      // {
      //   title: "الموظفين",
      //   icon: ICONS.settings,
      //   path: "/dashboard/users",
      //    index: "8",
      //  },
      {
        title: "جهات الاتصال",
        icon: ICONS.phone,
        path: "/dashboard/contacts",
        index: "13",
      },
      {
        title: "التهيئة الإبتدائيّة",
        icon: ICONS.settings,
        path: "/setup-wizard",
        index: "14",
      },
      /* {
        title:"الإعدادات",
        icon: ICONS.settings,
        path: "/dashboard/settings",
        index: "11",
     
      },*/
    ],
  },
];

export default navConfig;
