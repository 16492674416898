import { createSlice } from "@reduxjs/toolkit";
import {
  createEmployeeService,
  getEmployeeService,
  getGroupService,
  getUsersNonEmployeeService,
  createGroupService,
  removeUserFromGroupService,
  getEmployeeSideBarService,
  requestVacationService,
  updateEmployeeService,
  getAllVacations,
  getPendingVacations,
  vacationReq,
  getEmployeeServiceList,
  getClientByPhoneService,
  getEmpProfileService,
  getUsersService,
  //
  getSessionCustomizeService,
  getSessionCustomizeForUserService,
  updateSessionCustomizeForUserService,
  //
  getVisibleColumsCustomizeService,
  getVisibleColumsCustomizeForUserService,
  updateVisibleColumsCustomizeForUserService,
} from "../services/users_service";
import { usersColumnsList } from "../../utils/columnsLists";
import { toast } from "react-toastify";

// ----------------------------------------------------------------------

const initialState = {
  isLoadingCreateUser: false,
  isLoadingUpdateUser: false,
  isLoading: false,
  isLoadingCreate: false,
  employees: [],
  employeesList: [],
  count: null,
  groups: [],
  usersWithoutGroup: [],
  isLoadingRemove: false,
  employeesSideBar: {},
  isLoadingEmployeesSideBar: false,
  usersColumnsList: usersColumnsList,
  isLoadingAllVacations: false,
  isLoadingPendingVacations: false,
  pendingVacations: [],
  isLoadingvacationReq: false,
  client_type: null,
  balance_status: null,
  matter_status: null,
  id_card_type: null,
  empLastPage: null,
  empNext: "",
  isLoadingProfile: false,
  empProfile: {},
  users: [],
  isLoadingUsers: false,
  usersNext: null,
  usersLastPage: null,
  permissions: null,
  //
  custome: {},
  customeUser: {},
  isLoadingCutome: false,
  isLoadingCutomeUser: false,
  isLoadingCreateCutomeUser: false,
  isLoadingCreateVisibleCutomeUser: false,
  customeVisible: {},
  customeVisibleUser: {},
};

const slice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUsersColumnsList: (state, action) => {
      state.usersColumnsList = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoadingAllVacations = action.payload;
    },
    setClientType: (state, action) => {
      state.client_type = action.payload;
    },
    setBalanceStatus: (state, action) => {
      state.balance_status = action.payload;
    },
    setMatterStatus: (state, action) => {
      state.matter_status = action.payload;
    },
    setIdCardType: (state, action) => {
      state.id_card_type = action.payload;
    },
    setUserPermissions: (state, action) => {
      state.permissions = action.payload;
    },
  },
  extraReducers: {
    // ==> CREATE USER
    [createEmployeeService.fulfilled]: (state, action) => {
      state.isLoadingCreateUser = false;
    },
    [createEmployeeService.rejected]: (state, action) => {
      state.isLoadingCreateUser = false;
    },
    [createEmployeeService.pending]: (state) => {
      state.isLoadingCreateUser = true;
    },
    // ==> UPDATE USER
    [updateEmployeeService.fulfilled]: (state, action) => {
      state.isLoadingUpdateUser = false;
    },
    [updateEmployeeService.rejected]: (state, action) => {
      state.isLoadingUpdateUser = false;
    },
    [updateEmployeeService.pending]: (state) => {
      state.isLoadingUpdateUser = true;
    },

    // ==> GET USER
    [getEmployeeService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.employees = action.payload.data;
    },
    [getEmployeeService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getEmployeeService.pending]: (state) => {
      state.isLoading = true;
    },
    // ==> GET USER LIST
    [getEmployeeServiceList.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.employeesList = action.payload.data.data;
      state.count = action.payload.data.count;
      state.empLastPage = action.payload.data.last_page;
      state.empNext = action.payload.data.next;
    },
    [getEmployeeServiceList.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getEmployeeServiceList.pending]: (state) => {
      state.isLoading = true;
    },

    // ==> GET GROUP
    [getGroupService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.groups = action.payload.data;
    },
    [getGroupService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getGroupService.pending]: (state) => {
      state.isLoading = true;
    },

    // ==> GET USERS NON EMPLOYEE
    [getUsersNonEmployeeService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.usersWithoutGroup = action.payload.data;
    },
    [getUsersNonEmployeeService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getUsersNonEmployeeService.pending]: (state) => {
      state.isLoading = true;
    },

    // ==> POST GROUp
    [createGroupService.fulfilled]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createGroupService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createGroupService.pending]: (state) => {
      state.isLoadingCreate = true;
    },

    // ==> REMOVE USER FROM GROUp
    [removeUserFromGroupService.fulfilled]: (state, action) => {
      state.isLoadingRemove = false;
    },
    [removeUserFromGroupService.rejected]: (state, action) => {
      state.isLoadingRemove = false;
    },
    [removeUserFromGroupService.pending]: (state) => {
      state.isLoadingRemove = true;
    },

    // ==> GET SidBar
    [getEmployeeSideBarService.fulfilled]: (state, action) => {
      state.isLoadingEmployeesSideBar = false;
      state.employeesSideBar = action.payload.data;
    },
    [getEmployeeSideBarService.rejected]: (state, action) => {
      state.isLoadingEmployeesSideBar = false;
    },
    [getEmployeeSideBarService.pending]: (state) => {
      state.isLoadingEmployeesSideBar = true;
    },

    // ==> Request Vecation
    [requestVacationService.fulfilled]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [requestVacationService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
      toast.error(
        action?.payload?.errors[0] || "حدث خطأ أثناء إنشاء إضافة إجازة"
      );
    },
    [requestVacationService.pending]: (state) => {
      state.isLoadingCreate = true;
    },
    // GET =>  GET ALL VACATIONS

    [getAllVacations.fulfilled]: (state, action) => {
      state.isLoadingAllVacations = false;
      state.allVacations = action.payload;
    },
    [getAllVacations.rejected]: (state, action) => {
      state.isLoadingAllVacations = false;
    },
    [getAllVacations.pending]: (state) => {
      state.isLoadingAllVacations = true;
    },
    // GET =>  GET Pending VACATIONS

    [getPendingVacations.fulfilled]: (state, action) => {
      state.isLoadingPendingVacations = false;
      state.pendingVacations = action.payload;
    },
    [getPendingVacations.rejected]: (state, action) => {
      state.isLoadingPendingVacations = false;
    },
    [getPendingVacations.pending]: (state) => {
      state.isLoadingPendingVacations = true;
    },
    [vacationReq.fulfilled]: (state, action) => {
      state.isLoadingvacationReq = false;
    },
    [vacationReq.rejected]: (state, action) => {
      state.isLoadingvacationReq = false;
    },
    [vacationReq.pending]: (state) => {
      state.isLoadingvacationReq = true;
    },
    [getEmpProfileService.fulfilled]: (state, action) => {
      state.isLoadingProfile = false;
      state.empProfile = action.payload.data;
    },
    [getEmpProfileService.rejected]: (state, action) => {
      state.isLoadingProfile = false;
      toast.error(action?.payload?.errors[0] || "حدث خطأ أثناء جلب المعلومات ");
    },
    [getEmpProfileService.pending]: (state) => {
      state.isLoadingProfile = true;
    },
    [getUsersService.fulfilled]: (state, action) => {
      state.isLoadingUsers = false;
      state.users = action.payload.data.data;
      state.usersCount = action.payload.data.data;
      state.usersNext = action.payload.data.count;
      state.usersLastPage = action.payload.data.last_page;
    },
    [getUsersService.rejected]: (state, action) => {
      state.isLoadingUsers = false;
    },
    [getUsersService.pending]: (state) => {
      state.isLoadingUsers = true;
    },

    // ==> GET Custome
    [getSessionCustomizeService.fulfilled]: (state, action) => {
      state.isLoadingCutome = false;
      state.custome = action.payload.data.data;
    },
    [getSessionCustomizeService.rejected]: (state, action) => {
      state.isLoadingCutome = false;
    },
    [getSessionCustomizeService.pending]: (state) => {
      state.isLoadingCutome = true;
    },

    // ==> GET Custome user
    [getSessionCustomizeForUserService.fulfilled]: (state, action) => {
      state.isLoadingCutomeUser = false;
      state.customeUser = action.payload?.data?.data;
    },
    [getSessionCustomizeForUserService.rejected]: (state, action) => {
      state.isLoadingCutomeUser = false;
    },
    [getSessionCustomizeForUserService.pending]: (state) => {
      state.isLoadingCutomeUser = true;
    },

    // ==> PUT Custome user
    [updateSessionCustomizeForUserService.fulfilled]: (state, action) => {
      state.isLoadingCreateCutomeUser = false;
    },
    [updateSessionCustomizeForUserService.rejected]: (state, action) => {
      state.isLoadingCreateCutomeUser = false;
    },
    [updateSessionCustomizeForUserService.pending]: (state) => {
      state.isLoadingCreateCutomeUser = true;
    },

    // ==> GET Custome visible
    [getVisibleColumsCustomizeService.fulfilled]: (state, action) => {
      state.isLoadingCutome = false;
      state.customeVisible = action.payload.data.data;
    },
    [getVisibleColumsCustomizeService.rejected]: (state, action) => {
      state.isLoadingCutome = false;
    },
    [getVisibleColumsCustomizeService.pending]: (state) => {
      state.isLoadingCutome = true;
    },

    // ==> GET Custome visible user
    [getVisibleColumsCustomizeForUserService.fulfilled]: (state, action) => {
      state.isLoadingCutome = false;
      state.customeVisibleUser = action.payload.data.data;
    },
    [getVisibleColumsCustomizeForUserService.rejected]: (state, action) => {
      state.isLoadingCutome = false;
    },
    [getVisibleColumsCustomizeForUserService.pending]: (state) => {
      state.isLoadingCutome = true;
    },

    // ==> PUT Custome user visible
    [updateVisibleColumsCustomizeForUserService.fulfilled]: (state, action) => {
      state.isLoadingCreateVisibleCutomeUser = false;
    },
    [updateVisibleColumsCustomizeForUserService.rejected]: (state, action) => {
      state.isLoadingCreateVisibleCutomeUser = false;
    },
    [updateVisibleColumsCustomizeForUserService.pending]: (state) => {
      state.isLoadingCreateVisibleCutomeUser = true;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setUsersColumnsList,
  setIsLoading,
  setClientType,
  setBalanceStatus,
  setMatterStatus,
  setIdCardType,
  setUserPermissions,
} = slice.actions;
