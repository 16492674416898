import { Navigate, useRoutes } from "react-router-dom";

//element
import {
  LoginPage,
  WelcomePage,
  ResetPassword,
  OfficeDetails,
  AddNewUserPage,
  LawsuitPage,
  CreateLawsuit,
  EditLawsuit,
  AttachmentUpload,
  AddNewSession,
  AddExpertSession,
  AddAdminstrationProcedure,
  AddExcutiveProcedure,
  AddNewClientPage,
  EditSessionPage,
  EditExpertSession,
  EditAdminstrationProcedure,
  EditExcutiveProcedure,
  StatementsPage,
  ReceiptVoucherPage,
  InvoicesPage,
  CreateInvoicePage,
  CreateInvoiceMatterPage,
  CreateNewOpponents,
  RollPage,
  RollGeneration,
  ClientsPage,
  AddNewUser,
  OverViewPage,
  EditClientPage,
  EmployeePage,
  AddNewEmployee,
  EditEmployee,
  PersonalProfilePage,
  VacationsPage,
  SessionsPage,
  AccountsPage,
  AccountStatement,
  GeneralExpensesPage,
  ContactsPage,
  TaskProcedurePage,
  CustodyExpensesPage,
  NewStatementsPage,
  NewCompaniesPage,
  CreateExpenseStatement,
  NewIndividualsPage,
  CreateIndividualsExpenseStatement,
  StatementCompaniesDetails,
  StatementIndividualDetails,
  GeneralCalender,
  AssetsPage,
  CreateNewAsset,
  ContractsPage,
  AddnewInvoice,
  ContractDetailsPage,
  //
  CreateAttachmentPage,
} from "./elements";

import DashboardLayout from "../layouts/dashboard/DashboardLayout";

import UsersPage from "../pages/dashboard/users/UsersPage";

import Statistics from "../pages/dashboard/Statistics/Statistics";

import SearchOppontent from "../pages/auth/SearchOppontent/SearchOppontent";

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    // Auth
    {
      path: "/",
      children: [
        {
          element: <Navigate to="auth/welcome-page" replace />,
          index: true,
        },
        { path: "auth/welcome-page", element: <WelcomePage /> },
        { path: "auth/login", element: <LoginPage /> },
        { path: "auth/reset-password", element: <ResetPassword /> },
      ],
    },

    // Dashboard
    {
      path: "dashboard",
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/overview" replace />, index: true },
        { path: "overview", element: <OverViewPage /> },
        { path: "search-oppontnet", element: <SearchOppontent /> },

        //lawsuit
        {
          path: "lawsuit",
          children: [
            { path: "", element: <LawsuitPage /> },
            { path: "add-new-lawsuit", element: <CreateLawsuit /> },
            { path: "edit-lawsuit", element: <EditLawsuit /> },
            { path: "upload-attachment", element: <AttachmentUpload /> },
            { path: "add-session", element: <AddNewSession /> },
            { path: "add-expert-session", element: <AddExpertSession /> },
            {
              path: "add-adminstration-procedure",
              element: <AddAdminstrationProcedure />,
            },
            {
              path: "add-excutive-procedure",
              element: <AddExcutiveProcedure />,
            },
            { path: "add-new-client", element: <AddNewClientPage /> },
            { path: "edit-client", element: <EditClientPage /> },
            { path: "create-attachment", element: <CreateAttachmentPage /> },

            { path: "edit-session", element: <EditSessionPage /> },
            { path: "edit-expert-session", element: <EditExpertSession /> },
            {
              path: "edit-adminstration-session",
              element: <EditAdminstrationProcedure />,
            },
            {
              path: "edit-excutive-session",
              element: <EditExcutiveProcedure />,
            },

            {
              path: "add-invoice-matter",
              element: <CreateInvoiceMatterPage />,
            },
            { path: "add-new-opponent", element: <CreateNewOpponents /> },
          ],
        },

        {
          path: "sessions",
          children: [{ path: "", element: <SessionsPage /> }],
        },

        //finance
        {
          path: "finance",
          children: [
            {
              path: "statements",
              element: <StatementsPage />,
            },
            {
              path: "receipt-voucher",
              element: <ReceiptVoucherPage />,
            },

            {
              path: "invoices",
              element: <InvoicesPage />,
            },
            {
              path: "add-new-invoice",
              element: <CreateInvoicePage />,
            },
            {
              path: "accounts",
              element: <AccountsPage />,
            },

            {
              path: "accounts-statments",
              element: <AccountStatement />,
            },

            {
              path: "general-expenses",
              element: <GeneralExpensesPage />,
            },

            {
              path: "custody-expenses",
              element: <CustodyExpensesPage />,
            },

            {
              path: "judicial-statements",
              element: <NewStatementsPage />,
            },
            // company
            {
              path: "company-statements",
              element: <NewCompaniesPage />,
            },
            {
              path: "expense-statements",
              element: <CreateExpenseStatement />,
            },

            {
              path: "company-statements-details",
              element: <StatementCompaniesDetails />,
            },

            // Individuals
            {
              path: "individuals-statements",
              element: <NewIndividualsPage />,
            },
            {
              path: "individuals-expense-statements",
              element: <CreateIndividualsExpenseStatement />,
            },

            {
              path: "individual-statements-details",
              element: <StatementIndividualDetails />,
            },

            // new
            {
              path: "assets",
              element: <AssetsPage />,
            },

            {
              path: "add-new-asset",
              element: <CreateNewAsset />,
            },

            {
              path: "contracts",
              element: <ContractsPage />,
            },

            {
              path: "add-new-invoice-finance-contract",
              element: <AddnewInvoice />,
            },
            {
              path: "contract-details",
              element: <ContractDetailsPage />,
            },
          ],
        },

        //users
        {
          path: "users",
          children: [
            { path: "", element: <UsersPage /> },
            { path: "add-new-user", element: <AddNewUser /> },
          ],
        },

        //reports
        {
          path: "reports",
          children: [
            { path: "", element: <RollPage /> },
            { path: "roll-generation", element: <RollGeneration /> },
          ],
        },

        //clients
        {
          path: "clients",
          children: [{ path: "", element: <ClientsPage /> }],
        },

        //personal-profilem
        {
          path: "personal-profile",
          children: [{ path: "", element: <PersonalProfilePage /> }],
        },

        //hr
        {
          path: "hr",
          children: [
            {
              path: "employee",

              children: [
                { path: "", element: <EmployeePage /> },
                { path: "add-new-employee", element: <AddNewEmployee /> },
                { path: "edit-employee", element: <EditEmployee /> },
              ],
            },
            {
              path: "vacations",
              children: [{ path: "", element: <VacationsPage /> }],
            },
            { path: "general-calender", element: <GeneralCalender /> },
          ],
        },

        //statistics
        {
          path: "statistics",
          children: [{ path: "", element: <Statistics /> }],
        },

        {
          path: "contacts",
          children: [{ path: "", element: <ContactsPage /> }],
        },

        { path: "tasks-procedures", element: <TaskProcedurePage /> },
      ],
    },

    //

    {
      path: "setup-wizard",
      //element: <DashboardLayout />,
      children: [
        { path: "", element: <OfficeDetails /> },
        { path: "add-new-user", element: <AddNewUserPage /> },
      ],
    },
  ]);
}
