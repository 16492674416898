import { createAsyncThunk } from "@reduxjs/toolkit";
// urls
import * as URLS from "../../urls";
//axios
import axios from "../../utils/axios";
//_________________________________________________

// GET =>  GET INVOICES
export const getInvoicesService = createAsyncThunk(
  "finance/getInvoicesService",
  async ({ page, search_value }, thunkAPI) => {
    let url = `${URLS.getInvoicesURL}?page=${page}`;
    if (search_value) {
      url += `&search_value=${search_value}`;
    }
    try {
      const response = await axios.get(url);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  CREATE PAYMENT
export const createPaymentService = createAsyncThunk(
  "finance/createPaymentService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.createPaymentURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  CREATE STATEMENT
export const createStatementsService = createAsyncThunk(
  "finance/createStatementsService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.createStatementsURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  CREATE PAYMENT RECEPIPT
export const createPaymentReceiptService = createAsyncThunk(
  "finance/createPaymentReceiptService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.createPaymentReceiptURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
export const getMattersByClient = createAsyncThunk(
  "finance/getMattersByClient",
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.get(`/get_matters_by_client/${id}`);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const createFinanceFilterService = createAsyncThunk(
  "finance/createFinanceFilterService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.createFilterInvoivces, data);

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const exportStatementsService = createAsyncThunk(
  "finance/exportStatementsService",
  async ({ params, page }, thunkAPI) => {
    try {
      let url = `${URLS.exportStatementsURL}`;
      if (page) {
        url += `?page=${page}`;
      }
      const response = await axios.get(url, {
        params: { ...params },
      });

      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const getImportExportsService = createAsyncThunk(
  "finance/getImportExportsService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(URLS.getImportExportsURL, {
        params: { ...params },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const getPaymentReceiptsListService = createAsyncThunk(
  "finance/getPaymentReceiptsListService",
  async ({ params, page }, thunkAPI) => {
    try {
      let url = `${URLS.getPaymentReceiptsListURL}`;
      if (page !== undefined && page !== null) {
        url += `?page=${encodeURIComponent(page)}`;
      }
      if (params) {
        Object.keys(params).forEach((key) => {
          if (params[key] !== undefined && params[key] !== null) {
            url += url.includes("?")
              ? `&${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
              : `?${encodeURIComponent(key)}=${encodeURIComponent(
                  params[key]
                )}`;
          }
        });
      }
      const response = await axios.get(url);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const getGeneralExpensesService = createAsyncThunk(
  "finance/getGeneralExpensesService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(URLS.getGeneralExpensesURL, {
        params: { ...params },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const createGeneralExpensesService = createAsyncThunk(
  "finance/createGeneralExpensesService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.createGeneralExpensesURL, data, {
        headers: {
          Accept: "*/*",
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET => send Email To Client
export const sendEmailToClientService = createAsyncThunk(
  "finance/sendEmailToClientService",
  async (id, thunkAPI) => {
    try {
      const response = await axios.get(
        `${URLS.sendToClientURL}/${id}/send_to_client/`
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
export const getCustudyExpenseService = createAsyncThunk(
  "finance/getCustudyExpenseService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.get(
        `/custody_expenses_for_employee/${data?.id}/get_all/`,
        {
          params: {
            ...data,
          },
        }
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>     CREATE EXPENS STATMENTS
export const createExpensesCustudy = createAsyncThunk(
  "finance/createExpensesCustudy",
  async ({ data }, thunkAPI) => {
    try {
      const response = await axios.post(`/custody_expenses/create/`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET =>     GET EXPENS STATMENTS

export const expenseStatementsService = createAsyncThunk(
  "finance/expenseStatementsService",
  async ({ search_value, id, page }, thunkAPI) => {
    try {
      let url = `/expense-statements/get_all/?page=${page}`;
      if (search_value) {
        url += `&search_value=${search_value}`;
      }
      const response = await axios.get(url);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
// GET =>     CREATE EXPENS MATTER
export const createExpenseMatterService = createAsyncThunk(
  "finance/createExpenseMatterService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(`/create_expense_matter/`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
// GET =>     CHECK EXPENS IF EXSIST
export const checkExpense = createAsyncThunk(
  "finance/checkExpense",
  async (data, thunkAPI) => {
    try {
      const response = await axios.get(
        `/check_if_expenses_exists_for_matter/`,
        {
          params: {
            ...data?.data,
          },
        }
      );

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>     CREATE EXPENS STATMENTS
export const createStatementsIndividualService = createAsyncThunk(
  "finance/createStatementsIndividualService",
  async (_, thunkAPI) => {
    try {
      const response = await axios.post(
        `/expense-statements/create-for-individual/`
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
// POST =>     CREATE EXPENS STATMENTS
export const createStatementsCompanyService = createAsyncThunk(
  "finance/createStatementsCompanyService",
  async ({ data }, thunkAPI) => {
    try {
      const response = await axios.post(
        `/expense-statements/create-for-company/`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const getExpensesMattersService = createAsyncThunk(
  "finance/getExpensesOppService",
  async ({ id, page }, thunkAPI) => {
    try {
      let url = `/expense-statements/${id}/expense-matters/get_all/?page=${page}`;

      const response = await axios.get(url);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const getAllContractsService = createAsyncThunk(
  "finance/getAllContractsService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(`${URLS.getAllContractsURL}`, {
        params: { ...params, page_size: 10 },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// PUT =>  HAND OVER TO THE RESPONSIBLE
export const handOverRequestService = createAsyncThunk(
  "finance/handOverRequestService",
  async (id, thunkAPI) => {
    try {
      const response = await axios.put(
        `/expense-statements/${id}/hand-over-to-the-responsible/`, null
      );

      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const getAssetsService = createAsyncThunk(
  "finance/getAssetsService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(`${URLS.getAssetsURL}`/*, {
        params: { ...params, page_size: 10 },
      }*/);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);


export const getAssetsTypeService = createAsyncThunk(
  "finance/getAssetsTypeService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(`${URLS.getAssetsTypeURL}`/*, {
        params: { ...params, page_size: 10 },
      }*/);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const createAssetService = createAsyncThunk(
  "finance/createAssetService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(`${URLS.createAssetURL}`, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);