import { createAsyncThunk } from "@reduxjs/toolkit";
// urls
import * as URLS from "../../urls";
//axios
import axios from "../../utils/axios";
//_________________________________________________

// GET =>  All countries
export const getAllCountries = createAsyncThunk(
  "general/getAllCountries",
  async (thunkAPI) => {
    try {
      const response = await axios.get(URLS.countriesURL);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET =>  All jobs
export const getAllJobs = createAsyncThunk(
  "general/getAlljobs",
  async (thunkAPI) => {
    try {
      const response = await axios.get(URLS.jobsURL);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET =>  All currencies
export const getAllCurrencies = createAsyncThunk(
  "general/getAllcurrencies",
  async (thunkAPI) => {
    try {
      const response = await axios.get(URLS.currenciesURL);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  CREATE JOB
export const createJobServices = createAsyncThunk(
  "general/createJobServices",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.createjobURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET =>  All CLIENTS
export const getAllClientsService = createAsyncThunk(
  "general/getClient",
  async ({ page, search_value,type }, thunkAPI) => {
    try {
      let url = `${URLS.getClientsURL}?page=${page}`;
      if (type) {
        url += `&type=${type}`;
      }
      if (search_value) {
        url += `&search_value=${encodeURIComponent(search_value)}`;
      }

      const response = await axios.get(url);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET =>  FILTER CLIENT SERVICE
export const filterClientService = createAsyncThunk(
  "general/filterClientService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.clientsFilterURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET =>  All CLIENT BY ID
export const getClientByIdService = createAsyncThunk(
  "general/getClientById",
  async (id, thunkAPI) => {
    try {
      const response = await axios.get(`${URLS.getClientURL}/${id}`);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// DELETE =>  DELETE CLIENT
export const deleteClientsService = createAsyncThunk(
  "general/deleteClient",
  async (id, thunkAPI) => {
    try {
      const response = await axios.delete(`${URLS.deleteClientURL}/${id}`);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// UPDATE =>  UPDATE CLIENT
export const updateClientsService = createAsyncThunk(
  "general/updateClients",
  async (params, thunkAPI) => {
    try {
      const response = await axios.put(
        `${URLS.updateClientURL}/${params.id}/`,
        params?.data
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET =>  All Opponents
export const getOpponentsService = createAsyncThunk(
  "general/getOpponents",
  async ({ page, page_size, search_value }, thunkAPI) => {
    let url = `${URLS.getOpponentsURL}?page=${page}`;
    if (page_size) {
      url += `&page_size=${page_size}`;
    } if (search_value) {
      url += `&search_value=${encodeURIComponent(search_value)}`;
    }
    try {
      const response = await axios.get(url);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//GET => getAdministrativeEmployees
export const getAdministrativeService = createAsyncThunk(
  "general/getAdministrativeEmployees",
  async ({ page, page_size }, thunkAPI) => {
    try {
      let url = `${URLS.getAdministrativeEmployeesURL}?page=${page}`;
      if (page_size) {
        url += `&page_size=${page_size}`;
      }
      const response = await axios.get(url);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//GET => get Lawer Service
export const getLawyersService = createAsyncThunk(
  "general/getLawyersService",
  async ({ page, page_size }, thunkAPI) => {
    try {
      let url = `${URLS.getLawyersURL}?page=${page}`;
      if (page_size) {
        url += `&page_size=${page_size}`;
      }
      const response = await axios.get(url);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//GET => get Consultants Service
export const getConsultantsService = createAsyncThunk(
  "general/getConsultants",
  async ({ page, page_size }, thunkAPI) => {
    try {
      let url = `${URLS.getConsultantsURL}?page=${page}`;
      if (page_size) {
        url += `&page_size=${page_size}`;
      }
      const response = await axios.get(url);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//GET => get Sub Att Intinal Service
export const getSubAttIntinalService = createAsyncThunk(
  "general/getSubAttIntinal",
  async (thunkAPI) => {
    try {
      const response = await axios.get(URLS.getSubAttIntinalURL);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//GET => get Sub Att Appeal Service
export const getSubAttAppealService = createAsyncThunk(
  "general/getSubAttAppeal",
  async (thunkAPI) => {
    try {
      const response = await axios.get(URLS.getSubAttAppealURL);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//GET => get Sub Att Discrimination Service
export const getSubAttDiscriminationService = createAsyncThunk(
  "general/getSubAttDiscrimination",
  async (thunkAPI) => {
    try {
      const response = await axios.get(URLS.getSubAttDiscriminationURL);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//POST => create Opponent Service
export const createOpponentService = createAsyncThunk(
  "general/createOpponent",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.createOpponentURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//POST => Create Client Service
export const createClientService = createAsyncThunk(
  "general/createClient",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.createClientURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//

//GET => get Accounting Branch
export const getAccountingBranchService = createAsyncThunk(
  "general/getAccountingBranchService",
  async (thunkAPI) => {
    try {
      const response = await axios.get(URLS.getAccountingBranchURL);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//GET => get CARDS SERVICE
export const getCardsService = createAsyncThunk(
  "general/getCardsService",
  async (thunkAPI) => {
    try {
      const response = await axios.get(URLS.getCardsURL);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//POST => CREATE ROLE SERVICE
export const createRoleService = createAsyncThunk(
  "general/createRole",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.createRoleURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//POST => CREATE Groups SERVICE
export const getGroupsService = createAsyncThunk(
  "general/getGroups",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(URLS.getGroups);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
//GET => get Schedules
export const getSchedules = createAsyncThunk(
  "general/getSchedules",
  async ({ id, year, month }, thunkAPI) => {
    try {
      const response = await axios.get(
        `${URLS.employees}/${id}/monthly-schedule/?year=${year}&month=${month}`
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
//GET => get employee details
export const getEmployeeDetails = createAsyncThunk(
  "general/getEmployeeDetails",
  async ({ id }, thunkAPI) => {
    try {
      const response = await axios.get(`${URLS.employees}/${id}/`);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

//

export const resetPasswordService = createAsyncThunk(
  "general/resetPasswordService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.resetPasswordURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

export const getAllClientsListService = createAsyncThunk(
  "general/getAllClientsListService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.get(URLS.getClientsListURL);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET =>  get CLIENT BY PHONE

export const getClientByPhoneService = createAsyncThunk(
  "general/getClientByPhoneService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.getClientByPhoneURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET =>  All contacts
export const getAllContactsService = createAsyncThunk(
  "general/getAllContactsService",
  async (thunkAPI) => {
    try {
      const response = await axios.get(URLS.getAllContactsURL);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  CREATE Contact
export const createContactServices = createAsyncThunk(
  "general/createContactServices",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.createContactURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);



// POST =>  CREATE START WORK
export const startWorkService = createAsyncThunk(
  "general/startWorkService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.startWorkURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);


// POST =>  CREATE END WORK
export const endWorkService = createAsyncThunk(
  "general/endWorkService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.patch(URLS.endWorkURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// getContractType
export const getContractTypeService = createAsyncThunk(
  "general/getContractTypeService",
  async (thunkAPI) => {
    try {
      const response = await axios.get(URLS.getContractTypeURL);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);


// GET INVOICE FOR CLIENT
export const getInvoicesForClientService = createAsyncThunk(
  "general/getInvoicesForClientService",
  async (params,thunkAPI) => {
    try {
      const response = await axios.get(`${URLS.getInvoicesForClientURL}/${params?.id}`, {
     //   params: { ...params },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);